import {PropsWithChildren} from 'react';

import {makeStyles, mergeClasses, Subtitle1, tokens} from '@fluentui/react-components';
import ResourceGroupTree from './ResourceGroupTree';

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground2,
    display: 'flex',
    rowGap: tokens.spacingHorizontalS,
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: tokens.spacingVerticalXXXL,
    paddingRight: tokens.spacingVerticalXXXL,
    paddingTop: tokens.spacingVerticalXXL,
    position: 'relative',
    overflow: 'hidden'
  },
  border: {
    boxShadow: tokens.shadow4,
    borderTopLeftRadius: tokens.borderRadiusXLarge
  },
  header: {
    width: '100%',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    borderBottomColor: tokens.colorNeutralStroke3,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    '& > span': {
      fontFamily: 'Lubalin'
    }
  },
  point: {
    color: tokens.colorBrandBackground
  }
});

interface PageContentProps {
  title: string;
  showResourceGroupTree?: boolean;
  testId?: string;
}

const PageContent = ({
  children,
  showResourceGroupTree,
  testId,
  title
}: PropsWithChildren<PageContentProps>) => {
  const styles = useStyles();

  return (
    <>
      {showResourceGroupTree && <ResourceGroupTree />}
      <div
        className={
          showResourceGroupTree ? mergeClasses(styles.wrapper, styles.border) : styles.wrapper
        }
        data-testid={testId}
      >
        <div className={styles.header}>
          <Subtitle1 truncate wrap={false}>
            {title}
          </Subtitle1>
          <Subtitle1 className={styles.point}>.</Subtitle1>
        </div>
        {children}
      </div>
    </>
  );
};

export default PageContent;
